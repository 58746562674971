/**
 * Progress bars
 */
 
 .progress{
    width: 100%;
    height: 5px;
    background: darken($glass-color, 2%);
    border-radius: 2px;
    margin-top: 5px;
     
     .progress-1{
         width: 10%;
         height: 100%;
         background-color: $red-color;
         border-radius: 2px;
    }
     
     .progress-2{
         width: 20%;
         height: 100%;
         background-color: $red-color;
         border-radius: 2px;
    }
     
     .progress-3{
         width: 30%;
         height: 100%;
         background-color: $orange-color;
         border-radius: 2px;
    }
     
     .progress-4{
         width: 40%;
         height: 100%;
         background-color: $orange-color;
         border-radius: 2px;
    }
     
     .progress-5{
         width: 50%;
         height: 100%;
         background-color: $yellow-color;
         border-radius: 2px;
    }
     
     .progress-6{
         width: 60%;
         height: 100%;
         background-color: $yellow-color;
         border-radius: 2px;
    }
     
     .progress-7{
         width: 70%;
         height: 100%;
         background-color: $green-color;
         border-radius: 2px;
    }
     
     .progress-8{
         width: 80%;
         height: 100%;
         background-color: $green-color;
         border-radius: 2px;
    }
     
     .progress-9{
         width: 90%;
         height: 100%;
         background-color: $green-color;
         border-radius: 2px;
    }
     
     .progress-10{
         width: 100%;
         height: 100%;
         background-color: $green-color;
         border-radius: 2px;
    }
     
 }
