@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   14px;
$base-font-weight: 100;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     4px;
$height-unit:     140px;
$block-padding:    30px;

$text-color:       #fefefe;
$background-color: #082736;
$brand-color:      #2a7ae2;

$border-radius: 7px;


$white-color: #fefefe;
$glass-color: lighten($background-color, 2%);
$grey-color:       #546E7A;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);
$teal-color: #26A69A;
$green-color: #4CAF50;
$lime-color: #C6FF00;
$yellow-color: #FFEB3B;
$orange-color: #FB8C00;
$red-color: #F44336;
$pink-color: #E91E63;
$purple-color: #9C27B0;
$blue-color: #2196F3;

// Width of the content area
$content-width:    1000px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",  
        "blocks",
        "layout",        
        "colors",
        "helpers",
        "progressbar",
        "syntax-highlighting",
        "divTable"
;
