/**
 * Site header
 */
.site-header {  
   
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.title{
    padding: $spacing-unit * 3;
    float: left;
    background: $red-color;
}

.site-title {
    font-size: 16px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
    
    &,
    &:visited {
        color: $text-color;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;
        font-size: 14px;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {   
    padding: $spacing-unit 0;
    text-align: center;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

/**
 * Grid
 */
.col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.col {
    float: left;
    margin-bottom: $spacing-unit / 4;
    padding-left: $spacing-unit / 2;
}

.col-1 {
    width: -webkit-calc(8.33333333% - (#{$spacing-unit} / 2));
    width:         calc(8.33333333% - (#{$spacing-unit} / 2));
}

.col-2 {
    width: -webkit-calc(16.66666667% - (#{$spacing-unit} / 2));
    width:         calc(16.66666667% - (#{$spacing-unit} / 2));
}

.col-3 {
    width: -webkit-calc(25% - (#{$spacing-unit} / 2));
    width:         calc(25% - (#{$spacing-unit} / 2));  
}

.col-4 {
    width: -webkit-calc(33.33333333% - (#{$spacing-unit} / 2));
    width:         calc(33.33333333% - (#{$spacing-unit} / 2));
}

.col-5 {
    width: -webkit-calc(41.66666667% - (#{$spacing-unit} / 2));
    width:         calc(41.66666667% - (#{$spacing-unit} / 2));
}

.col-6 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
}

.col-7 {
    width: -webkit-calc(58.33333333% - (#{$spacing-unit} / 2));
    width:         calc(58.33333333% - (#{$spacing-unit} / 2));
}

.col-8 {
    width: -webkit-calc(66.66666667% - (#{$spacing-unit} / 2));
    width:         calc(66.66666667% - (#{$spacing-unit} / 2));
}

.col-9 {
    width: -webkit-calc(75% - (#{$spacing-unit} / 2));
    width:         calc(75% - (#{$spacing-unit} / 2));
}

.col-10 {
    width: -webkit-calc(83.33333333% - (#{$spacing-unit} / 2));
    width:         calc(83.33333333% - (#{$spacing-unit} / 2));
}

.col-11 {
    width: -webkit-calc(91.66666667% - (#{$spacing-unit} / 2));
    width:         calc(91.66666667% - (#{$spacing-unit} / 2));
}

.col-12 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12{
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

/**
 * Fixed Heights
 */
.height-1{
    min-height: $height-unit;
}

.height-2{
    min-height: $height-unit * 2;
}

.height-3{
    min-height: $height-unit * 3;
}

/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}
