/**
 * Colors
 */

.white{
    background: $white-color;
}

.white-text{
    color: $white-color;
}

.glass{
    background: $glass-color;
}

.glass-text{
    color: lighten($glass-color, 15%);
}

.grey{
    background: $grey-color;
}

.grey-text{
    color: $grey-color;
}

.teal{
    background: $teal-color;
}

.teal-text{
    color: $teal-color;
}

.green{
    background: $green-color;
}

.green-text{
    color: $green-color;
}

.lime{
    background: $lime-color;
}

.lime-text{
    color: $lime-color;
}

.yellow{
    background: $yellow-color;
}

.yellow-text{
    color: $yellow-color;
}

.orange{
    background: $orange-color;
}

.orange-text{
    color: $orange-color;
}

.red{
    background: $red-color;
}

.red-text{
    color: $red-color;
}

.pink{
    background: $pink-color;
}

.pink-text{
    color: $pink-color;
}

.purple{
    background: $purple-color;
}

.purple-text{
    color: $purple-color;
}

.blue{
    background: $blue-color;
}

.blue-text{
    color: $blue-color;
}